.page-404 {
  background: url('/assets/img/background/error-404/img-bg-photo.png') no-repeat;
  background: -webkit-image-set(
      url('/assets/img/background/error-404/img-bg-photo.png') 1x,
      url('/assets/img/background/error-404/img-bg-photo@2x.png') 2x,
      url('/assets/img/background/error-404/img-bg-photo@3x.png') 3x
    )
    no-repeat;
  background-position: center;
  height: 100vh;
  min-height: 45.9rem;
}
@media screen and (min-width: 768px) {
  .page-404 {
    background-position: center;
    min-height: 53.3rem;
  }
}
@media screen and (min-width: 992px) {
  .page-404 {
    background-size: cover;
    min-height: 60.3rem;
  }
}

.error-404 .row {
  margin: 0;
}

.error-404 .banner-logo {
  margin-bottom: 4rem;
  margin-top: 10.4rem;
  width: 14.1rem;
}

.error-404 h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: none;
}

.error-404 h2 {
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 3.8rem;
}

.error-404 .btn {
  width: 21.7rem;
}
.error-404 .btn p {
  font-size: 1.8rem;
  margin-left: 1.8rem;
  margin-top: 0.8rem;
  text-align: left;
  text-transform: none;
}

@media screen and (min-width: 768px) {
  .error-404 .banner-logo {
    margin-top: 10vh;
    width: 16rem;
  }
  .error-404 h1 {
    font-size: 3rem;
    margin-bottom: 0.4rem;
  }
  .error-404 .group-logo {
    height: 5rem;
    left: 55%;
    margin-top: 10rem;
    position: absolute;
    top: 15%;
  }
  .error-404 h2 {
    color: #fff;
    margin-bottom: 4.5rem;
  }
  .error-404 .icon-hand {
    width: 8rem;
  }
  .error-404 .icon-projets {
    width: 10.9rem;
  }
  .error-404 .icon-contact {
    margin-bottom: 2.3rem;
    width: 6.1rem;
  }
  .error-404 .btn {
    width: 19rem;
  }
  .error-404 .btn p {
    font-size: 1.6rem;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .error-404 .banner-logo {
    margin-bottom: 6vh;
    margin-top: 7vh;
    width: 30rem;
  }
  .error-404 h1 {
    font-size: 3vw;
    margin-bottom: 1vh;
  }
  .error-404 .group-logo {
    height: 8rem;
    left: 58%;
    margin-top: 17rem;
    top: 8%;
  }
  .error-404 h2 {
    color: #fff;
    margin-bottom: 5vh;
  }
  .error-404 .icon-hand {
    width: 13rem;
  }
  .error-404 .icon-projets {
    width: 16.8rem;
  }
  .error-404 .icon-contact {
    margin-bottom: 2.8rem;
    width: 9.5rem;
  }
  .error-404 .btn {
    width: 24rem;
  }
  .error-404 .btn p {
    margin-left: 1.5rem;
  }
}
